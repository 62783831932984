'use strict';

Gri.module({
  name: 'form-alpha',
  ieVersion: null,
  $el: $('.form-alpha'),
  container: '.form-alpha',
  fn: function () {
    
  }
});
